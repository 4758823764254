<template>
  <div class="container-fluid bg-dark p-5 rounded-lg">
    <h4>Create New Site</h4>
    <div class="pt-4">
      <div class="row flex-wrap">
        <div class="col-12 col-sm-6 col-md-7 form-group">
          <label for="name">
            Site Name
            <sup class="text-danger">*</sup>
          </label>
          <input
            id="name"
            type="text"
            class="form-control"
            v-model="models.name"
          />
        </div>
        <div class="col form-group">
          <label for="latitude">
            Latitude
            <sup class="text-danger">*</sup>
          </label>
          <input
            id="latitude"
            type="text"
            class="form-control"
            v-model="models.lat"
            @input="sanitizeCoordinate('lat', $event)"
          />
        </div>
        <div class="col form-group">
          <label for="longitude">
            Longitude
            <sup class="text-danger">*</sup>
          </label>
          <input
            id="longitude"
            type="text"
            class="form-control"
            v-model="models.lng"
            @input="sanitizeCoordinate('lng', $event)"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6 form-group">
          <label for="geojson">Geojson URL</label>
          <input
            id="geojson"
            type="text"
            class="form-control"
            v-model="models.geojson"
          />
        </div>
        <div class="col-12 col-sm-6 form-group">
          <label for="boundary">Boundary URL</label>
          <input
            id="boundary"
            type="text"
            class="form-control"
            v-model="models.boundary"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm form-group">
          <label for="region">
            Region
            <sup class="text-danger">*</sup>
          </label>
          <select id="region" class="custom-select" v-model="models.region">
            <option :value="null" disabled>-- Select an option</option>
            <option :value="region.id" v-for="region in regions">
              {{ region.name }}
            </option>
          </select>
        </div>
        <div class="col-6 col-sm form-group">
          <label for="type">
            Site Type
            <sup class="text-danger">*</sup>
          </label>
          <select id="type" class="custom-select" v-model="models.type">
            <option :value="null" disabled>-- Select an option</option>
            <option value="landslide">Landslide</option>
            <option value="flood">Flood</option>
            <option value="riot">Riot</option>
            <option value="others">Others</option>
          </select>
        </div>
        <div class="col-6 col-sm form-group">
          <label for="region">
            Severity
            <sup class="text-danger">*</sup>
          </label>
          <select id="severity" class="custom-select" v-model="models.severity">
            <option :value="null" disabled>-- Select an option</option>
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </select>
        </div>
      </div>

      <div class="text-right pt-3">
        <button
          class="btn btn-success border-secondary px-4 py-2"
          @click="saveSite()"
        >
          <i class="fas fa-save mr-2" />
          Save
        </button>
      </div>
    </div>

    <spinner :show="isLoading" />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      isLoading: false,
      regions: [],
      models: {
        name: null,
        lat: null,
        lng: null,
        geojson: null,
        boundary: null,
        type: null,
        severity: "Low",
        region: null,
      },
      emptyModels: {
        name: null,
        lat: null,
        lng: null,
        geojson: null,
        boundary: null,
        type: null,
        severity: "Low",
        region: null,
      },
    };
  },
  methods: {
    sanitizeCoordinate(type, e) {
      this.models[type] = this.Helper.sanitizeNumber(e.target.value);
    },
    async getRegions() {
      this.isLoading = true;

      const [regionCall, regionCallErr] = await this.Helper.handle(
        this.API.get("regions")
      );

      if (!regionCallErr && regionCall.status == 200) {
        this.regions = regionCall.data;
      }

      this.isLoading = false;
    },
    async saveSite() {
      if (
        !this.models.name ||
        !this.models.lat ||
        !this.models.lng ||
        !this.models.type ||
        !this.models.severity ||
        !this.models.region
      ) {
        Swal.fire(
          "<h5 class='mb-0'>All field marked with <sup class='text-danger'>*</sup> is required</h5>",
          "",
          "error"
        );
      } else {
        this.isLoading = true;

        const [postCall, postCallErr] = await this.Helper.handle(
          this.API.post("sites", this.models)
        );

        if (!postCallErr && postCall.status == 200) {
          Swal.fire({
            title: "<h5 class='mb-0'>New site created!</h5>",
            icon: "success",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: "View Site",
            denyButtonText: "Add another site",
            cancelButtonText: "Go to Dashboard",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({
                name: "disaster.site",
                params: { id: postCall.data.id },
              });
            } else if (result.isDenied) {
              this.models = { ...this.emptyModels };
            } else if (result.isDismissed) {
              this.$router.push({ name: "disaster.dashboard" });
            }
          });
        }

        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getRegions();
  },
};
</script>